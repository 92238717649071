<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              WhatYouWant
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2 text-center">
            ลืมรหัสผ่าน
          </p>
          <!--          <p class="mb-2">-->
          <!--            Make your app management easy and fun!-->
          <!--          </p>-->
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="อีเมล"
              placeholder="อีเมลที่ใช้สมัครสมาชิก"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-btn
              block
              @click="resetPassword"
              color="primary"
              class="mt-6"
            >
              ส่งข้อมูล
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <!--          <span class="me-2">-->
          <!--            Already have an account?-->
          <!--          </span>-->
          <router-link :to="{ name: 'pages-login' }">
            กลับไปยังหน้าการเข้าสู่ระบบ
          </router-link>
        </v-card-text>

      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import instance_user from "@/services/user";
import Swal from "sweetalert2";

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    resetPassword() {
      var data = {
        email: this.email
      }
      this.$store.state.isLoading = true;
      instance_user.forgotPassword(data).then(res => {
        this.$store.state.isLoading = false;
        if (res.data.success) {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        } else {

          Swal.fire({
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }
      }).catch(err => {
        this.$store.state.isLoading = false;
        Swal.fire({
          icon: 'error',
          title: err.message,
          showConfirmButton: false,
          timer: 1500
        })
      })

    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
